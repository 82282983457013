/* ******************************************* TWEAK : START : Making "All DashboardHome AntDCards" have box shadow ****************************************** */
.DashboardHomeSection .ant-card {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome AntDCards" have box shadow ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Cards" and its body expand to full height ****************************************** */
.DashboardHomeSection > .ant-row > .ant-col > div {
	height: 100%;
}

.DashboardHomeSection .ant-card {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.DashboardHomeSection .ant-card .ant-card-body {
	flex: 1;
}

.DashboardHomeSection .ant-card .ant-card-head {
	/* background: #276678; */
	background: #383e56;
	color: white;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Cards" expand to full height ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Card's AntD Card Action Buttons" look somewhat nice with current structure ****************************************** */

.DashboardHomeSection .ant-card .ant-card-actions {
	background: rgb(230, 229, 229);
	display: flex;
	justify-content: flex-end;
}

.DashboardHomeSection .ant-card .ant-card-actions li {
	width: auto !important;
	margin-right: 5px;
}

.DashboardHomeSection .ant-card .ant-card-actions .ant-btn {
	height: auto;
	white-space: normal;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 3px 5px;
	margin-top: 5px;
	background: #276678;
	color: white;
	border-radius: 4px;
	font-size: 0.95em;
}

.DashboardHomeSection .ant-card .ant-card-actions .ant-btn:hover {
	background: #276678da;
}

.DashboardHomeSection .ant-btn .anticon {
	order: 2;
	margin: 0 5px;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Card's AntD Buttons" look somewhat nice with current structure ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Card's AntD Descriptions Label" become gray  ****************************************** */
.DashboardHomeSection .ant-descriptions-item-label {
	color: gray;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Card's AntD Descriptions Label" become gray ****************************************** */
