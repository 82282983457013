/* Making All Tabs Holder (Antd Tab Component) wrapper take remaining full height of page. */
.ManageUsersSection__tabSet {
	flex: 1;
	height: 100%;
}

/* Making AntD Tabs Component Wrapper TO it's Individual Tab content has 100% height.
   So in individual tab contents we can easily center things with flex box.
*/
.ManageUsersSection__tabSet > .ant-tabs,
.ManageUsersSection__tabSet > .ant-tabs .ant-tabs-content {
	height: 100%;
}
