@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500&display=swap);
/* Google Fonts */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiListItem-container{
  list-style: none;
}

.pichu{
  max-width: 99% !important;
}
.MuiIconButton-sizeSmall {
  padding: 0!important;
}
.MuiIcon .MuiIconButton-label {
  min-width: 105px !important;
}

.dashboard-auth-header{
  text-align: center;
  margin-bottom: 0;
  font-family: 'Oswald', sans-serif !important;
  font-size: 2em;
  color: rgb(73, 73, 73);
}
.ant-page-header .ant-avatar  {
  border-radius: 0% !important;
  width: 180px !important;
}
.auth-box-w{
  margin-top: 10px;
  margin-bottom: 10px;
}
.editicon{
  float:right;
}
.site-card-border-less-wrapper {
  padding: 15px;
  border-radius: 5px;
}
.purchsenow{
  font-size: 14px;
}
.additem{
  color: #1976d2;
  text-align: right;
  margin-bottom: 10px;
}
.welcometitle{
  font-size: 14px;
  margin-right: 10px;
  margin-left: 5px;
}
.companyheading{
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  font-variant: tabular-nums;
  font-weight: 700;
  font-size: 20px;
  padding-left: 22px;
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }
  .ant-layout-sider, .ant-menu.ant-menu-dark {
    background: #045269 !important;
    color:#000;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #2aa1ce;
}
.ant-layout-content, .ant-modal-content, .ant-modal-header{
  border-radius: 10px !important;
}
.ant-popover{
  left:195px !important;
  width: 15%;
}
.ant-popover-inner-content {
  padding: 0px !important; 
}
body {
    padding: 20px;
  }
  #content {
    width: 100%;
  }
  .react-grid-layout {
    background: #eee;
    margin-top: 10px;
  }
  .layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -webkit-columns: 120px;
    columns: 120px;
  }
  .react-grid-item {
    box-sizing: border-box;
  }
  .react-grid-item:not(.react-grid-placeholder) {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(197, 197, 197);
    border-radius: 10px;
    box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -webkit-grab;
  }
  
  .toolbox {
    background-color: #dfd;
    width: 100%;
    height: 120px;
    overflow: scroll;
  }
  
  .hide-button {
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }
  
  .toolbox__title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .toolbox__items {
    display: block;
  }
  .toolbox__items__item {
    display: inline-block;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    background-color: #ddd;
  }
  .droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }
  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.dropping {
    visibility: hidden;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
  
  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }

  #x {
    position: absolute;
    color: #000;
    font-size:x-small;
    background:#FFF;
    border-radius: 20px;
    top: -5px;
    right: -5px;
    box-shadow: 1px 2px 5px rgb(174, 175, 175) inset, 1px 2px 5px rgb(174, 175, 175);
}

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ebf1f5;
  }
  
  .slider {
    margin: 50px 50px 0;
  }
  
  .charts {
    display: flex;
    justify-content: space-evenly;
    margin: 0 20px;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid lightgray;
  }
 
  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
 
  
  .charts {
    height: 100%;
    max-height: 850px;
    background-color: white;
    margin: 1% 3%;
    display: flex;
    justify-content: space-around;
    border-radius: 4px;
  }
  
  #barChart {
    width: 42%;
    height:850px;
    max-height: 850px;
  }
  
  #geoChart {
    height:850px;
    width: 55%;
    max-height: 850px;
  }
  .makeStyles-root-15 {
    width: 100% !important;
}

/* Making All Tabs Holder (Antd Tab Component) wrapper take remaining full height of page. */
.ManageOrganizationSection__tabSet {
	flex: 1 1;
	height: 100%;
}

/* Making AntD Tabs Component Wrapper TO it's Individual Tab content has 100% height.
   So in individual tab contents we can easily center things with flex box.
*/
.ManageOrganizationSection__tabSet > .ant-row,
.ManageOrganizationSection__tabSet > .ant-row > .ant-col,
.ManageOrganizationSection__tabSet > .ant-row > .ant-col > .ant-tabs,
.ManageOrganizationSection__tabSet
	> .ant-row
	> .ant-col
	> .ant-tabs
	.ant-tabs-content {
	height: 100%;
}

.OrganizationApps {
	margin: 0 auto;
	margin-top: 20px;
	max-width: 700px;
}

.OrganizationApps__byCompany {
	margin-bottom: 15px;
}

.OrganizationApps__byCompany__header {
	background: #383e56;
	color: white;
	padding: 8px 15px;
	border-radius: 4px 4px 0 0;
}

.OrganizationApps__byCompany__appList {
	background: rgb(248, 244, 244);
	padding: 10px;
	border-radius: 0 0 4px 4px;
}

.OrganizationApps__byCompany__appList img {
	max-width: 75px;
	cursor: pointer;
	margin: 0 5px;
}

.PurchaseAppsSection__body {
	flex: 1 1;
	padding: 0 20px;
}

.PurchaseAppsSection__body > div {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.PurchaseAppsSection__body__mainLoader {
	align-items: center;
	display: flex;
	flex: 1 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainError {
	align-items: center;
	display: flex;
	flex: 1 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainData {
	display: flex;
	flex: 1 1;
	flex-direction: column;
}

.PurchaseAppsSection__body__mainData__noSelection {
	align-items: center;
	display: flex;
	flex: 1 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainData__appList {
	margin-top: 20px;
}

.PurchaseAppsSection__body__mainData__appList____alreadyPurchasedLabel {
	font-style: italic;
}

.PurchaseAppsSection__body__mainData__appList____purchasedButton {
	text-transform: unset !important;
}

/* Similar to "< sm" breakpoints. */
@media only screen and (max-width: 576px) {
	/* Hiding App Descriptions that rendered inside MUI ListItem. */
	.PurchaseAppsSection__body__mainData__appList
		.MuiListItem-container
		.MuiListItemText-secondary {
		display: none;
	}
}

/* ******************************************* TWEAK : START : Making "All DashboardHome AntDCards" have box shadow ****************************************** */
.DashboardHomeSection .ant-card {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome AntDCards" have box shadow ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Cards" and its body expand to full height ****************************************** */
.DashboardHomeSection > .ant-row > .ant-col > div {
	height: 100%;
}

.DashboardHomeSection .ant-card {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.DashboardHomeSection .ant-card .ant-card-body {
	flex: 1 1;
}

.DashboardHomeSection .ant-card .ant-card-head {
	/* background: #276678; */
	background: #383e56;
	color: white;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Cards" expand to full height ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Card's AntD Card Action Buttons" look somewhat nice with current structure ****************************************** */

.DashboardHomeSection .ant-card .ant-card-actions {
	background: rgb(230, 229, 229);
	display: flex;
	justify-content: flex-end;
}

.DashboardHomeSection .ant-card .ant-card-actions li {
	width: auto !important;
	margin-right: 5px;
}

.DashboardHomeSection .ant-card .ant-card-actions .ant-btn {
	height: auto;
	white-space: normal;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 3px 5px;
	margin-top: 5px;
	background: #276678;
	color: white;
	border-radius: 4px;
	font-size: 0.95em;
}

.DashboardHomeSection .ant-card .ant-card-actions .ant-btn:hover {
	background: #276678da;
}

.DashboardHomeSection .ant-btn .anticon {
	order: 2;
	margin: 0 5px;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Card's AntD Buttons" look somewhat nice with current structure ****************************************** */

/* ******************************************* TWEAK : START : Making "All DashboardHome Card's AntD Descriptions Label" become gray  ****************************************** */
.DashboardHomeSection .ant-descriptions-item-label {
	color: gray;
}
/* ******************************************* TWEAK : END : Making "All DashboardHome Card's AntD Descriptions Label" become gray ****************************************** */

/* ******************************************* MAIN : START : Purchased App Icons Area ****************************************** */

.PurchasedAppsSummary__appListContainer > div > div {
	display: flex;
	flex-wrap: wrap;
}

.PurchasedAppsSummary__appListContainer > div > div img {
	max-width: 40px;
	height: 40px;
	margin: 2px;
}

.PurchasedAppsSummary__appListContainer__byCompanySection {
	margin: 15px 0;
	font-size: small;
	color: gray;
}
/* ******************************************* MAIN : END : Purchased App Icons Area ****************************************** */

.Dashboard {
	display: flex;
	flex-direction: column;
}

/* Top-Most App Bar. */
.Dashboard .Dashboard__mainDashboardAppHeader {
	padding-left: 15px !important;
}

/* Wrapping Main Header and Body */
.Dashboard__contentWrapper {
	background: white;
	margin: 10px;
	border-radius: 5px;
	padding: 15px 15px;
	display: flex;
	flex: 1 1;
	flex-direction: column;
}

/* ************************************* MAIN : START : Header Parts ********************************** */
/* Currently, This section contain things like Mobile Menu Toggler, Organization Selector, Title, Etc.... */

.Dashboard__header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 0 12px 0;
	margin: 0 0 12px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* This only displayed in <lg breakpoints. */
.Dashboard__header__mobileMenuToggle {
	display: none;
	margin-right: 10px;
	cursor: pointer;
}

.Dashboard__header__title {
	flex: 1 1;
}

.Dashboard__header__title h4 {
	text-align: center;
	margin-bottom: 0;
	font-family: 'Oswald', sans-serif !important;
	font-size: 2em;
	color: rgb(73, 73, 73);
}

.Dashboard__header__userActions {
	display: flex;
	align-items: center;
}

/* There is some misalignment with this antd button. Trying to fix it here. */
.Dashboard__header__userActions a {
	display: flex;
	padding: 0 10px;
	align-items: center;
	background-size: 5.66px 5.66px;
	background-image: linear-gradient(
		45deg,
		#ffffff 37.5%,
		#cee3f0 37.5%,
		#cee3f0 50%,
		#ffffff 50%,
		#ffffff 87.5%,
		#cee3f0 87.5%,
		#cee3f0 100%
	);
}
/* ************************************* MAIN : END : Header Parts ********************************** */

/* ************************************* MAIN : START : Body Parts ********************************** */
/* Currently, This section contain Main Menu & Renderings of selected sub page. */

.Dashboard__body {
	display: flex;
	flex: 1 1;
	position: relative;
}

.Dashboard__body__mainMenu {
	min-width: 250px;
	margin-right: 20px;
	background: rgb(248, 247, 247);
}

/* ********************* SUB: START : Tweaking MainMenu for Mobile Resolutions. ********************* */
/* Making this look like background overlay. */
.Dashboard__body__mainMenu--mobile---Open {
	display: inline !important;
	display: initial !important;
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	background: rgba(48, 47, 47, 0.726);
}

.Dashboard__body__mainMenu--mobile---Open ul {
	min-height: 100%;
	max-width: 250px;
	overflow: auto;
	background: rgb(248, 247, 247);
}
/* ********************* SUB: END : Tweaking MainMenu for Mobile Resolutions. ********************* */

.Dashboard__body__subPage {
	flex: 1 1;
	overflow: auto;
}
/* ************************************* MAIN : START : Body Parts ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */
/* Similar to "< lg" breakpoints. */
@media only screen and (max-width: 992px) {
	/* ********************* SUB: START : Re-Ordering Header Items. ********************* */
	.Dashboard__header__userActions {
		order: 1;
		flex-basis: 100%;
		text-align: right;
		background: rgb(197, 199, 209);
		padding: 10px;
		margin-top: -15px;
		margin-left: -15px;
		min-width: calc(100% + 30px);
		border-radius: 5px 5px 0 0;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	.Dashboard__header__title {
		order: 2;
		flex-basis: 100%;
		margin: 15px 0;
	}

	.Dashboard__header__mobileMenuToggle {
		display: inline;
		display: initial;
		order: 3;
	}

	.Dashboard__header__organizationSelector {
		order: 4;
		margin-left: auto;
	}
	/* ********************* SUB: END : Re-Ordering Header Items. ********************* */

	/* ********************* SUB: START : Handling Mobile Menu. ********************* */

	.Dashboard__body__mainMenu {
		display: none;
	}

	.Dashboard__body__mainMenu--mobile---Close {
		display: none;
	}
	/* ********************* SUB: END : Handling Mobile Menu. ********************* */
}

/* Similar to "< sm" breakpoints. */
@media only screen and (max-width: 400px) {
	/* Tweaking Antd Page Header at top most of page to be compatible in very small resolutions. */
	.Dashboard
		.Dashboard__mainDashboardAppHeader.ant-page-header
		.ant-page-header-heading-left {
		flex-wrap: wrap;
		justify-content: center;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

/* ************************************* TEMPORARY FIX: START : Global Tweak that applies to every AntD Page Header component. (Even in Sub Page Components) ********************************** */
.Dashboard .ant-page-header {
	padding-left: 0;
}

/* In very small resolutions. */
@media only screen and (max-width: 576px) {
	/* Tweaking Antd Page Header at top most of page to be compatible in very small resolutions. */
	.Dashboard .ant-page-header .ant-page-header-heading-left {
		display: flex;
		flex-wrap: wrap;
	}
}
/* ************************************* TEMPORARY FIX: START : Global Tweak that applies to every AntD Page Header component. (Even in Sub Page Components) ********************************** */

/* Making All Tabs Holder (Antd Tab Component) wrapper take remaining full height of page. */
.ManageUsersSection__tabSet {
	flex: 1 1;
	height: 100%;
}

/* Making AntD Tabs Component Wrapper TO it's Individual Tab content has 100% height.
   So in individual tab contents we can easily center things with flex box.
*/
.ManageUsersSection__tabSet > .ant-tabs,
.ManageUsersSection__tabSet > .ant-tabs .ant-tabs-content {
	height: 100%;
}

/* NOTE :
      - "GC-UTL" stands for "GlobalClass-UTILITY"
*/

.GC-UTL-hideElement {
	display: none;
}

.GC-UTL-fullFlexHeight {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/* Utility to center item horizontally and vertically. */
.GC-UTL-flexSuperCenter {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

/* NOTE :
      - "GC" in ClassNames indicate 'Global Class'
*/

/* ******************************************* MAIN : START : Special Class To Customize Antd Forms ****************************************** */
/* NOTE : When this class added to AntD root form element all sub elements, form-items get customized look. */
.SCC-customizedAntdForm {
	margin: 10px auto !important;
	max-width: 700px;
}

/* Wrapper div that wraps both AntD Label Part and form Input Part. */
.SCC-customizedAntdForm .ant-form-item {
	margin-bottom: 15px;
}

/* AntD Label Part Wrapper */
.SCC-customizedAntdForm .ant-form-item-label {
	background: #264653;
	padding: 6px 15px !important;
	border-radius: 4px 0 0 4px;
	margin-bottom: 0 !important;
	min-width: 200px;
	display: flex;
	align-items: center;
}

/* AntD Label itself */
.SCC-customizedAntdForm .ant-form-item-label label {
	margin-bottom: 0;
	color: white !important;
}

/* Colon(:) that appear in AntD Label end */
.SCC-customizedAntdForm .ant-form-item-label label::after {
	display: none;
}

/* AntD Input Part Wrapper */
.SCC-customizedAntdForm .ant-form-item-control-input {
	background: #d9dfe1;
	padding: 0px 10px;
	border-radius: 0 4px 4px 0;
	height: 100%;
}

/* Nested AntD Collapsible Background */
.SCC-customizedAntdForm .ant-form-item-control-input .ant-collapse-item {
	background: #e1e5e6;
	margin: 10px 0;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Nested Labels */
.SCC-customizedAntdForm .ant-form-item-control-input .ant-form-item-label {
	background: #457b9d;
}

/* Nested Inputs */
.SCC-customizedAntdForm
	.ant-form-item-control-input
	.ant-form-item-control-input {
	background: #c6d3d9;
}

/* ******************************************* MAIN : END : Special Class To Customize Antd Forms ****************************************** */

/* NOTE :
      - "SCC" in ClassNames indicate 'Special Customizing Class'
*/

