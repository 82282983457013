/* NOTE :
      - "GC-UTL" stands for "GlobalClass-UTILITY"
*/

.GC-UTL-hideElement {
	display: none;
}

.GC-UTL-fullFlexHeight {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/* Utility to center item horizontally and vertically. */
.GC-UTL-flexSuperCenter {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
