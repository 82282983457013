/* ******************************************* MAIN : START : Purchased App Icons Area ****************************************** */

.PurchasedAppsSummary__appListContainer > div > div {
	display: flex;
	flex-wrap: wrap;
}

.PurchasedAppsSummary__appListContainer > div > div img {
	max-width: 40px;
	height: 40px;
	margin: 2px;
}

.PurchasedAppsSummary__appListContainer__byCompanySection {
	margin: 15px 0;
	font-size: small;
	color: gray;
}
/* ******************************************* MAIN : END : Purchased App Icons Area ****************************************** */
