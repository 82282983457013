.PurchaseAppsSection__body {
	flex: 1;
	padding: 0 20px;
}

.PurchaseAppsSection__body > div {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.PurchaseAppsSection__body__mainLoader {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainError {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainData {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.PurchaseAppsSection__body__mainData__noSelection {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
}

.PurchaseAppsSection__body__mainData__appList {
	margin-top: 20px;
}

.PurchaseAppsSection__body__mainData__appList____alreadyPurchasedLabel {
	font-style: italic;
}

.PurchaseAppsSection__body__mainData__appList____purchasedButton {
	text-transform: unset !important;
}

/* Similar to "< sm" breakpoints. */
@media only screen and (max-width: 576px) {
	/* Hiding App Descriptions that rendered inside MUI ListItem. */
	.PurchaseAppsSection__body__mainData__appList
		.MuiListItem-container
		.MuiListItemText-secondary {
		display: none;
	}
}
