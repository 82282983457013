.Dashboard {
	display: flex;
	flex-direction: column;
}

/* Top-Most App Bar. */
.Dashboard .Dashboard__mainDashboardAppHeader {
	padding-left: 15px !important;
}

/* Wrapping Main Header and Body */
.Dashboard__contentWrapper {
	background: white;
	margin: 10px;
	border-radius: 5px;
	padding: 15px 15px;
	display: flex;
	flex: 1;
	flex-direction: column;
}

/* ************************************* MAIN : START : Header Parts ********************************** */
/* Currently, This section contain things like Mobile Menu Toggler, Organization Selector, Title, Etc.... */

.Dashboard__header {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 0 12px 0;
	margin: 0 0 12px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* This only displayed in <lg breakpoints. */
.Dashboard__header__mobileMenuToggle {
	display: none;
	margin-right: 10px;
	cursor: pointer;
}

.Dashboard__header__title {
	flex: 1;
}

.Dashboard__header__title h4 {
	text-align: center;
	margin-bottom: 0;
	font-family: 'Oswald', sans-serif !important;
	font-size: 2em;
	color: rgb(73, 73, 73);
}

.Dashboard__header__userActions {
	display: flex;
	align-items: center;
}

/* There is some misalignment with this antd button. Trying to fix it here. */
.Dashboard__header__userActions a {
	display: flex;
	padding: 0 10px;
	align-items: center;
	background-size: 5.66px 5.66px;
	background-image: linear-gradient(
		45deg,
		#ffffff 37.5%,
		#cee3f0 37.5%,
		#cee3f0 50%,
		#ffffff 50%,
		#ffffff 87.5%,
		#cee3f0 87.5%,
		#cee3f0 100%
	);
}
/* ************************************* MAIN : END : Header Parts ********************************** */

/* ************************************* MAIN : START : Body Parts ********************************** */
/* Currently, This section contain Main Menu & Renderings of selected sub page. */

.Dashboard__body {
	display: flex;
	flex: 1;
	position: relative;
}

.Dashboard__body__mainMenu {
	min-width: 250px;
	margin-right: 20px;
	background: rgb(248, 247, 247);
}

/* ********************* SUB: START : Tweaking MainMenu for Mobile Resolutions. ********************* */
/* Making this look like background overlay. */
.Dashboard__body__mainMenu--mobile---Open {
	display: initial !important;
	position: absolute;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	background: rgba(48, 47, 47, 0.726);
}

.Dashboard__body__mainMenu--mobile---Open ul {
	min-height: 100%;
	max-width: 250px;
	overflow: auto;
	background: rgb(248, 247, 247);
}
/* ********************* SUB: END : Tweaking MainMenu for Mobile Resolutions. ********************* */

.Dashboard__body__subPage {
	flex: 1;
	overflow: auto;
}
/* ************************************* MAIN : START : Body Parts ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */
/* Similar to "< lg" breakpoints. */
@media only screen and (max-width: 992px) {
	/* ********************* SUB: START : Re-Ordering Header Items. ********************* */
	.Dashboard__header__userActions {
		order: 1;
		flex-basis: 100%;
		text-align: right;
		background: rgb(197, 199, 209);
		padding: 10px;
		margin-top: -15px;
		margin-left: -15px;
		min-width: calc(100% + 30px);
		border-radius: 5px 5px 0 0;
		justify-content: flex-end;
		flex-wrap: wrap;
	}

	.Dashboard__header__title {
		order: 2;
		flex-basis: 100%;
		margin: 15px 0;
	}

	.Dashboard__header__mobileMenuToggle {
		display: initial;
		order: 3;
	}

	.Dashboard__header__organizationSelector {
		order: 4;
		margin-left: auto;
	}
	/* ********************* SUB: END : Re-Ordering Header Items. ********************* */

	/* ********************* SUB: START : Handling Mobile Menu. ********************* */

	.Dashboard__body__mainMenu {
		display: none;
	}

	.Dashboard__body__mainMenu--mobile---Close {
		display: none;
	}
	/* ********************* SUB: END : Handling Mobile Menu. ********************* */
}

/* Similar to "< sm" breakpoints. */
@media only screen and (max-width: 400px) {
	/* Tweaking Antd Page Header at top most of page to be compatible in very small resolutions. */
	.Dashboard
		.Dashboard__mainDashboardAppHeader.ant-page-header
		.ant-page-header-heading-left {
		flex-wrap: wrap;
		justify-content: center;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

/* ************************************* TEMPORARY FIX: START : Global Tweak that applies to every AntD Page Header component. (Even in Sub Page Components) ********************************** */
.Dashboard .ant-page-header {
	padding-left: 0;
}

/* In very small resolutions. */
@media only screen and (max-width: 576px) {
	/* Tweaking Antd Page Header at top most of page to be compatible in very small resolutions. */
	.Dashboard .ant-page-header .ant-page-header-heading-left {
		display: flex;
		flex-wrap: wrap;
	}
}
/* ************************************* TEMPORARY FIX: START : Global Tweak that applies to every AntD Page Header component. (Even in Sub Page Components) ********************************** */
