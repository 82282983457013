
.App {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ebf1f5;
  }
  
  .slider {
    margin: 50px 50px 0;
  }
  
  .charts {
    display: flex;
    justify-content: space-evenly;
    margin: 0 20px;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid lightgray;
  }
 
  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
 
  
  .charts {
    height: 100%;
    max-height: 850px;
    background-color: white;
    margin: 1% 3%;
    display: flex;
    justify-content: space-around;
    border-radius: 4px;
  }
  
  #barChart {
    width: 42%;
    height:850px;
    max-height: 850px;
  }
  
  #geoChart {
    height:850px;
    width: 55%;
    max-height: 850px;
  }
  .makeStyles-root-15 {
    width: 100% !important;
}
