/* ******************************************* MAIN : START : Special Class To Customize Antd Forms ****************************************** */
/* NOTE : When this class added to AntD root form element all sub elements, form-items get customized look. */
.SCC-customizedAntdForm {
	margin: 10px auto !important;
	max-width: 700px;
}

/* Wrapper div that wraps both AntD Label Part and form Input Part. */
.SCC-customizedAntdForm .ant-form-item {
	margin-bottom: 15px;
}

/* AntD Label Part Wrapper */
.SCC-customizedAntdForm .ant-form-item-label {
	background: #264653;
	padding: 6px 15px !important;
	border-radius: 4px 0 0 4px;
	margin-bottom: 0 !important;
	min-width: 200px;
	display: flex;
	align-items: center;
}

/* AntD Label itself */
.SCC-customizedAntdForm .ant-form-item-label label {
	margin-bottom: 0;
	color: white !important;
}

/* Colon(:) that appear in AntD Label end */
.SCC-customizedAntdForm .ant-form-item-label label::after {
	display: none;
}

/* AntD Input Part Wrapper */
.SCC-customizedAntdForm .ant-form-item-control-input {
	background: #d9dfe1;
	padding: 0px 10px;
	border-radius: 0 4px 4px 0;
	height: 100%;
}

/* Nested AntD Collapsible Background */
.SCC-customizedAntdForm .ant-form-item-control-input .ant-collapse-item {
	background: #e1e5e6;
	margin: 10px 0;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Nested Labels */
.SCC-customizedAntdForm .ant-form-item-control-input .ant-form-item-label {
	background: #457b9d;
}

/* Nested Inputs */
.SCC-customizedAntdForm
	.ant-form-item-control-input
	.ant-form-item-control-input {
	background: #c6d3d9;
}

/* ******************************************* MAIN : END : Special Class To Customize Antd Forms ****************************************** */
