.OrganizationApps {
	margin: 0 auto;
	margin-top: 20px;
	max-width: 700px;
}

.OrganizationApps__byCompany {
	margin-bottom: 15px;
}

.OrganizationApps__byCompany__header {
	background: #383e56;
	color: white;
	padding: 8px 15px;
	border-radius: 4px 4px 0 0;
}

.OrganizationApps__byCompany__appList {
	background: rgb(248, 244, 244);
	padding: 10px;
	border-radius: 0 0 4px 4px;
}

.OrganizationApps__byCompany__appList img {
	max-width: 75px;
	cursor: pointer;
	margin: 0 5px;
}
